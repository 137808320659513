$( function() {	
	if( !$('body.post-type-archive-portfolio').length) return
    let page = 1;

	const send_ajax = () =>{
		const post_type = $('input[name=post_type]').val();
		
		$('.portfolio-projects').trigger( "filter-ajax-before" );

		$.ajax({
			type : "post",
			dataType : "json",
			url : g.ajaxurl,
			data : {
						action: 'portfolio_filter', 
						post_type:post_type, 
						page:page,
						first_filter: $('.filter_1').val(), 
						second_filter: $('.filter_2').val(), 
						third_filter: $('.filter_3').val() 
			},
			success: function(response) {
				$('.portfolio-projects').html( response.items);
				$('.portfolio-projects').trigger( "filter-ajax-finish" );
				$('.portfolio-pagination').html(response.pagination)
				$("html, body").animate({ scrollTop: $('.portfolio-projects').offset().top - 150 });
			}
			}).done(function( data ) {
				if( data.visible_results == 10 ){
				$('.load_more a').removeClass('disabled');
			}
		});  
	}

	function change_page(p, $el){
		page = p;

		$('.pagination li .current').removeClass('current');
		$el.find('a').addClass('current');
		$el.find('span').addClass('current');
	}


    $(document).on( 'change', ".filters-container .filter select.filter-select",  function(event){
        event.preventDefault();
		event.stopPropagation();

		change_page( 1, $('.pagination li').first() ); //page, element
		
		send_ajax();
		
	});
	
	//ajax pagination
	$(document).on( 'click','body.post-type-archive-portfolio .pagination li',function(e){
		e.preventDefault();

		change_page( $(this).text(), $(this) );//page, element

		send_ajax();
	})
});

