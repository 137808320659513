import $ from 'jquery';
import ScrollOut from 'scroll-out';
import Swiper from 'swiper/bundle';

window.$ = $;
/*
	Passive listeners fix for Google Page Speed
*/
import './utils/passive-listeners';
import './utils/smoothscroll';
import './lib/filters';

// - Uncomment for slider module
// import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
// configure Swiper to use modules
// Swiper.use([Navigation, Pagination, Autoplay]);

/*
	If you want to pick and choose which modules to include, comment out the above and uncomment
	the line below
*/
// import whatInput from 'what-input';
import './lib/foundation-explicit-pieces';

$(document).foundation();

/*
	Common JS snippets. Uncomment as needed.
*/
$(function () {
	if ($('.clients-number').length) {
		$('.clients-number').html(g.term_num);
	}

	ScrollOut({
		onShown(el) {
			// use the web animation API
			// if you're over the cards element
			if (el.matches('.footer-scroll')) {
				// do countup
				$('.subscribe-nav').fadeOut('slow');
			}
		},
		onHidden(el) {
			// hide the element initially
			if (el.matches('.footer-scroll')) {
				// do countup
				$('.subscribe-nav').fadeIn('slow');
			}
		},
	});

	// only for large screens
	if (Foundation.MediaQuery.atLeast('large')) {
		let prev = 0;
		const $window = $(window);
		const nav = $('.scrollhide-nav');

		$window.on('scroll', function () {
			const scrollTop = $window.scrollTop();
			nav.toggleClass('hidden', scrollTop > prev && prev > 0);

			if (scrollTop < 10) {
				nav.removeClass('box-shadow');
			}
			if (scrollTop > 10 && !nav.hasClass('box-shadow')) {
				nav.addClass('box-shadow');
			}

			prev = scrollTop;
		});
	}

	$('.desktop-menu-toggle').click(function () {
		$(this).toggleClass('open');
		$('.desktop-menu').toggleClass('open');
		$(this).attr('aria-expanded') === 'false'
			? $(this).attr('aria-expanded', 'true')
			: $(this).attr('aria-expanded', 'false');
	});

	$('.scroll-up').click(function () {
		$('html, body').animate({ scrollTop: 0 }, 700);
		return false;
	});

	// $('.clients-slider').slick({
	// 	infinite: true,
	// 	slidesToShow: 4,
	// 	variableWidth: false,
	// 	slidesToScroll: 3,
	// 	autoplay: true,
	// 	responsive: [
	// 		{
	// 			breakpoint: 480,
	// 			settings: {
	// 				slidesToShow: 1,
	// 				slidesToScroll: 1,
	// 			},
	// 		},
	// 	],
	// });

	// $('.project-images').slick({
	// 	arrows: true,
	// 	responsive: [
	// 		{
	// 			breakpoint: 780,
	// 			settings: {
	// 				arrows: false,
	// 				dots: true,
	// 			},
	// 		},
	// 	],
	// });

	$(function() {
		new Swiper('.project-images-slider', {
			direction: 'horizontal',
			slidesPerView: 1,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			breakpoints: {
				0: {
					slidesPerView: 1,
				},
				640: {
					slidesPerView: 1,
				},
				1024: {
					slidesPerView: 1,
				},
			},
		});
	});

	$('.filter-button').click(function () {
		$(this).toggleClass('active');
		$('.filters-container').slideToggle(400);
	});

	// Hide the right blob on smaller bodies (pages)

	if ($('body').height() < 850 && $('.right-orange-blob').length) {
		$('.right-orange-blob').hide();
	}

	// Proper anchor scorll for about page
	$('.about-scroll a').click(function (e) {
		const navHeight = $('.site-navigation').height();
		e.preventDefault();
		$('html,body').animate(
			{
				scrollTop:
					$('#about-scroll-anchor').offset().top - navHeight - 150,
			},
			'slow'
		);
	});
});
